import { useEffect, useState } from "react";

const AnimatedInputProxy = ({ label, onChange, name, value, onBlur }) => {
	const [inputValue, setInputValue] = useState("");

	const handleOnChange = (e) => {
		setInputValue(e.target.value);

		onChange(e);
	};

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	console.log({value, inputValue})

	return (
		<div className="relative">
			<div className="relative">
				<input
					type="text"
					id={name}
					name={name}
					onChange={handleOnChange}
					onBlur={onBlur}
					value={inputValue ?? ""}
					className={`
						rounded-md peer w-full h-10 border-b-2 
						border-gray-300 focus:outline-none has-value focus:border-sky-500
					`}
				/>
				<label
					htmlFor={name}
					className={`
						absolute bg-white px-1 left-4 transition-all duration-100 ease-in-out 
						text-gray-500 ${
							!!inputValue ? " -top-2.5 text-xs " : " top-2 "
						}peer-placeholder-shown:text-gray-600 peer-placeholder-shown:text-xs
						 peer-placeholder-shown:top-2 peer-placeholder-shown:left-0 peer-focus:-top-2.5
						  peer-focus:text-sky-500 peer-focus:text-xs peer-focus:left-4`}
				>
					{label}
				</label>
			</div>
		</div>
	);
};

export default AnimatedInputProxy;
